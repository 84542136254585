import { ListItem, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import CustomReportValue from "./customReportValue";
import { Checkbox, IconButton, ListItemText } from "@mui/material";
import { Controller, useFieldArray } from "react-hook-form";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTranslation } from "../hooks/translation.hook";
import { translate } from "../../../utils/translation";
import { useCustomReport } from "../hooks/customReportData.hook";

export const  keyCustomReportFields = 'customReportFields';

function CustomReportRow({
  column,
  relation,
  startOrder,
  index,
  setOpen,
  ID_REPORT,
}) {

  const [expanded, setExpanded] = useState(false);
  const [currentField, setCurrentField] = useState();

  const { translations } = useTranslation();
  const { control, getValues } = useCustomReport();

  const toggleExpanded = () => {
    setExpanded(!expanded);
  }

  const { fields, append, remove } = useFieldArray({
    control,
    name: keyCustomReportFields
  });

  const isChecked = () => {
    return currentField
  }

  useEffect(() => {
    const currentValue = () => {
      const fieldValue = fields.find((f) => {
        return f.NOME_TABELA === relation.tableName &&
          f.NOME_TABELA_ALIAS === relation.columnName &&
          f.NOME_TABELA_ORIGEM === relation.tableNameOrigin &&
          f.NOME_TABELA_ORIGEM_ALIAS === relation.columnNameOrigin &&
          f.NOME_ATRIBUTO === column.columnName
      })
      return fieldValue;
    }
    const currentFieldValue = currentValue();
    setCurrentField(currentFieldValue)
  }, [isChecked])

  const handleValuesChange = (data) => {
    const currentFields = getValues(keyCustomReportFields);
    const index = currentFields.findIndex(field => field.NOME_TABELA === relation.tableName &&
      field.NOME_TABELA_ALIAS === relation.columnName &&
      field.NOME_TABELA_ORIGEM === relation.tableNameOrigin &&
      field.NOME_TABELA_ORIGEM_ALIAS === relation.columnNameOrigin &&
      field.NOME_ATRIBUTO === column.columnName);

    if (index !== -1) {
      remove(index);
      const newField = currentFields[index];
      newField.customReportFieldValues = data;
      append(newField);
    }
  }

  const handleChangeCheckbox = (checked) => {
    if(checked) {
      setExpanded(true);
    } else {
      setExpanded(false);
      handleValuesChange([]);
    }
  }

  const verifyIfHasDependence = () => {
    const currentFields = getValues(keyCustomReportFields);
    const toRemove: Array<number> = [];
    currentFields.map((currentFieldToFind, indexToFind) => {
      const founded = currentFields.find((currentField) => (currentFieldToFind.NOME_TABELA_ORIGEM === currentField.NOME_TABELA || currentFieldToFind.NOME_TABELA_ORIGEM === ''))
      if(!founded) {
        toRemove.push(indexToFind)
      }
    })
    remove(toRemove);
  }

  const handleCheckboxChange = (column, index, event) => {
    setExpanded(false);
    const currentFields = getValues(keyCustomReportFields);
    if (event.target.checked) {
      const newField = {
        ORDER: index + 1 + startOrder,
        NOME_TABELA: relation.tableName,
        NOME_TABELA_ALIAS: relation.columnName,
        NOME_TABELA_ORIGEM: relation.tableNameOrigin,
        NOME_TABELA_ORIGEM_ALIAS: relation.columnNameOrigin,
        NOME_ATRIBUTO: column.columnName,
      };

      if (!currentFields?.some(field => field.NOME_TABELA === relation.tableName &&
        field.NOME_TABELA_ALIAS === relation.columnName &&
        field.NOME_TABELA_ORIGEM === relation.tableNameOrigin &&
        field.NOME_TABELA_ORIGEM_ALIAS === relation.columnNameOrigin &&
        field.NOME_ATRIBUTO === column.columnName)) {
        append(newField);
      }
    } else {
      const index = currentFields.findIndex(field => field.NOME_TABELA === relation.tableName &&
        field.NOME_TABELA_ALIAS === relation.columnName &&
        field.NOME_TABELA_ORIGEM === relation.tableNameOrigin &&
        field.NOME_TABELA_ORIGEM_ALIAS === relation.columnNameOrigin &&
        field.NOME_ATRIBUTO === column.columnName);
      if (index !== -1) {
        remove(index);
      }

      verifyIfHasDependence();
    }
  };

  return (
    <>
      <ListItem
        key={`list-item-${Math.random()*20}`}
        style={{
          border: '1px solid',
          borderColor: '#bdbdbd',
          marginTop: '4px',
          borderRadius: '4px',
          backgroundColor: '#f5f5f5',
          paddingLeft: 12,
        }}
      >
        <Controller
          name={`customReportFields`}
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <Checkbox
              {...field}
              checked={isChecked() ? true : false}
              onChange={(event) => handleCheckboxChange(column, index, event)}
              inputProps={{ "aria-label": "controlled" }}
            />
          )}
        />
        <ListItemText primary={translate(relation.tableName, column.columnName, translations)} />
        {currentField &&
          <>
            <Switch
              checked={currentField?.customReportFieldValues?.length > 0}
              onChange={(event, checked) => handleChangeCheckbox(checked)}
            />
            <IconButton aria-label="collapse" onClick={() => toggleExpanded()}>
              {!expanded ? <KeyboardArrowLeftIcon /> : <KeyboardArrowUpIcon />}
            </IconButton>
          </>
        }
      </ListItem>
      {expanded &&
        <CustomReportValue
          onHandle={handleValuesChange}
          expanded={expanded}
          column={column}
          currentValue={currentField}
          relation={relation}
          setOpen={setOpen}
          ID_REPORT={ID_REPORT}
        />
      }
    </>
)}

export default CustomReportRow;