export const menuList = [
  {
    label: "Dashboard",
    items: [
      {
        label: "Pipeline",
        route: "/dashboard",
        claims: ["Invoice - Maintenance"],
      },
      {
        label: "Operational",
        items: [
          {
            label: "Invoice",
            route: "/invoice",
            claims: ["Invoice - Maintenance"],
          },
          {
            label: "Shipment Authorization",
            route: "/shipmentAuthorization",
            claims: [
              "Shipment Instruction - Maintenance",
              "Shipment Authorization - Visualization",
            ],
          },
          {
            label: "Process",
            route: "/boarding-process",
            claims: [
              "Process - Maintenance",
              "Process - Visualization"
            ],
          },
        ],
        claims: [],
      },
      {
        label: "Consult",
        route: "/consult",
        claims: ["Consult"],
      },
      {
        label: "Goods Triage",
        route: "/goods-triage",
        claims: [
          "Triage - Visualization",
          "Triage - Buyer",
          "Triage - Register",
          "Triage - Analysis"
        ],
      }
    ],
    claims: []
  },
  {
    label: "Reports",
    items: [
      {
        label: "Operational",
        items: [
          {
            label: "Invoice Pipeline",
            route: "/reports/operational-pipeline",
            claims: ["Reports - Operacional"],
          },
          {
            label: "Invoice Discrepancy",
            route: "/reports/operational-discrepancy",
            claims: ["Reports - Operacional"],
          },
          {
            label: "Pending",
            route: "/reports/operational-pending",
            claims: ["Reports - Operacional"],
          },
          {
            label: "Missing",
            route: "/reports/operational-missing",
            claims: ["Reports - Operacional"],
          },
          {
            label: "Receiving Control",
            route: "/reports/operational-receiving-control",
            claims: ["Reports - Operacional"],
          },
          {
            label: "Customs Audit",
            route: "/reports/customs-audit",
            claims: ["Reports - Operacional"],
          },
          {
            label: "Missing Entry Form",
            route: "/reports/operational-missing-entry-form",
            claims: ["Reports - Operacional"],
          },
        ],
        claims: [],
      },
      {
        label: "Invoice MIGO",
        route: "/reports/invoice-migo",
        claims: ["Reports - Invoice - MIGO"],
      },
      {
        label: "Triage",
        items: [
          {
            label: "Pipeline",
            route: "/reports/triage-pipeline",
            claims: ["Reports - Triage"],
          },
          {
            label: "Picking List",
            route: "/reports/triage-picking-list",
            claims: ["Reports - Triage"],
          },
        ],
        claims: [],
      },
      {
        label: "General",
        route: "/reports/general",
        claims: ["Reports - General"],
      },
      {
        label: "Inventory",
        route: "/reports/inventory",
        claims: ["Reports - Inventory"],
      },
      {
        label: "Send Visibilities",
        route: "/reports/send-visibilities",
        claims: ["Reports - Send Visibilities"],
      },
    ],
    claims: [],
  },
  {
    label: "Maintenance",
    items: [
      {
        label: "System",
        items: [
          {
            label: "Port/Airport",
            route: "/airports",
            claims: ["Airport - Visualization", "Airport - Maintenance"],
          },
          // {
          //   label: "Cargo Treatment",
          //   route: "/cargoTreatments",
          //   claims: [
          //     "Cargo Treatment - Maintenance",
          //     "Cargo Treatment - Visualization",
          //   ],
          // },
          {
            label: "Carrier",
            route: "/carrier",
            claims: ["Carrier - Maintenance", "Carrier - Visualization"],
          },
          {
            label: "Center (Plant)",
            route: "/centers",
            claims: ["Center - Visualization", "Center - Maintenance"],
          },
          {
            label: "Consolidation Center",
            route: "/consolidationCenters",
            claims: [
              "Consolidation Center - Visualization",
              "Consolidation Center - Maintenance",
            ],
          },
          {
            label: "Container Type",
            route: "/containerType",
            claims: [
              "Container Type - Visualization",
              "Container Type - Maintenance",
            ],
          },
          {
            label: "Country",
            route: "/countries",
            claims: ["Country - Visualization", "Country - Maintenance"],
          },
          {
            label: "Currency",
            route: "/currencies",
            claims: ["Currency - Visualization", "Currency - Maintenance"],
          },
          {
            label: "Custom Channel",
            route: "/customChannel",
            claims: [
              "Custom Channel - Visualization",
              "Custom Channel - Maintenance",
            ],
          },
          {
            label: "Custom Regime",
            route: "/customsRegimes",
            claims: [
              "Custom Regime - Visualization",
              "Custom Regime - Maintenance",
            ],
          },
          {
            label: "Discrepancy",
            route: "/discrepancies",
            claims: [
              "Discrepancy - Maintenance",
              "Discrepancy - Visualization",
            ],
          },
          {
            label: "Interface - Freigth Forwarder",
            route: "/freigthForwarders",
            claims: [
              "Interface Freight Forwarder - Visualization",
              "Interface Freight Forwarder - Maintenance",
            ],
          },
          {
            label: "Incoterms",
            route: "/incoterms",
            claims: ["Incoterms - Visualization", "Incoterms - Maintenance"],
          },
          {
            label: "Incoterms Complement",
            route: "/incotermsComplement",
            claims: [
              "Incoterms Compl - Visualization",
              "Incoterms Compl - Maintenance",
            ],
          },
          {
            label: "Location Bin",
            route: "/locationBin",
            claims: [
              "Location Bin - Visualization",
              "Location Bin - Maintenance",
            ],
          },
          {
            label: "Quarantine Code",
            route: "/quarantine",
            claims: ["Quarantine - Visualization", "Quarantine - Maintenance"],
          },
          {
            label: "Material Type",
            route: "/materialTypes",
            claims: [
              "Material Type - Visualization",
              "Material Type - Maintenance",
            ],
          },
          {
            label: "Package Type",
            route: "/packageType",
            claims: [
              "Package Type - Visualization",
              "Package Type - Maintenance",
            ],
          },
          {
            label: "Pick-up Zone",
            route: "/pickupZones",
            claims: [
              "Pickup Zone - Visualization",
              "Pickup Zone - Maintenance",
            ],
          },
          {
            label: "Rules for Freight Forwarder",
            route: "/freightForwarderRules",
            claims: [
              "Rules for Freight Forwarder - Visualization",
              "Rules for Freight Forwarder - Maintenance",
            ],
          },
          {
            label: "Service Level",
            route: "/serviceLevels",
            claims: [
              "Service Level - Visualization",
              "Service Level - Maintenance",
            ],
          },
          {
            label: "Shipment Instruction",
            route: "/shipmentInstructions",
            claims: [
              "Shipment Instruction - Maintenance",
              "Shipment Instruction - Visualization",
            ],
          },
          {
            label: "Unit Of Measurement",
            route: "/unitsOfMeasurement",
            claims: [
              "Unit Of Measurement - Visualization",
              "Unit Of Measurement - Maintenance",
            ],
          },
        ],
      },
      {
        label: "Access Control",
        route: "/accessControls",
        claims: [
          "Access Control - Visualization",
          "Access Control - Maintenance",
        ],
      },
      {
        label: "SLA",
        route: "/sla",
        claims: [
          "SLA - Visualization",
          "SLA - Maintenance",
        ],
      },
      {
        label: "Partner",
        route: "/partner",
        claims: [
          "Partner - Visualization",
          "Partner - Maintenance",
        ],
      },
      {
        label: "Emails - General",
        route: "/maintenanceEmail",
        claims: [
          "Email Type Center - Visualization",
          "Email Type Center - Maintenance",
        ],
      },
      {
        label: "Upload Suppliers Manager",
        route: "/uploadSuppliersManager",
        claims: [
          "Upload Suppliers Manager",
        ],
      },
      {
        label: "Label Data",
        route: "/labelData",
        claims: [
          "Label Data - Maintenance",
          "Label Data - Visualization",
        ],
      },
      {
        label: "Automatic Authorization",
        items: [
          {
            label: "Rules",
            route: "/automaticAuthorization",
            claims: [
              "Shipment Authorization - Rules - Visualization",
              "Shipment Authorization - Rules - Maintenance",
            ],
          },
          {
            label: "Scheduler",
            route: "/automaticAuthorizationScheduler",
            claims: [
              "Shipment Authorization - Scheduler - Visualization",
              "Shipment Authorization - Scheduler - Maintenance",
            ],
          },
          {
            label: "Log",
            route: "/automaticAuthorizationLog",
            claims: [
              "Shipment Authorization - Log",
            ],
          },
        ],
        claims: [],
      },
      {
        label: "Automatic Scheduler Report",
        route: "/reportSchedule",
        claims: [
          "Schedule Reports - Maintenance",
        ],
      },
    ],
    claims: [],
  },
  {
    label: "System Support",
    items: [
      {
        label: "EDI File - Consult",
        route: "/edi-file",
        claims: ["EDI File - Consult"],
      },
      {
        label: "Log Interface",
        route: "/log-interface",
        claims: ["Log Interface"],
      },
      {
        label: "Log Email",
        route: "/log-email",
        claims: ["Log Mail"],
      },
      {
        label: "Interface Reprocess",
        route: "/interface-reprocess",
        claims: ["Interface - Reprocess"],
      },
    ],
    claims: [],
  },
];
