import { LocalizationProvider } from "@mui/x-date-pickers";
import CustomReportRow from "./customReportRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useFieldsToHide } from "../hooks/fieldsToHide.hook";
import { useState } from "react";
import { Snackbar, SnackbarCloseReason } from "@mui/material";

export default function CustomReportFields({
  entity,
  relation,
  startOrder,
  ID_RELATORIO,
}) {

  const { fieldsToHide } = useFieldsToHide();
  const [open, setOpen] = useState(() => {
    return localStorage.getItem("open-warning") === "true";
  });

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    localStorage.setItem("open-warning", "false");
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        color="warning"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="The date range must be a maximum of 3 months to schedule export"
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {entity?.columns?.filter(
          (column) => (
            !fieldsToHide.global.includes(column.columnName)
            && (!column.isPrimary || column.columnName === 'ID_AUTORIZACAO_EMBARQUE' || column.columnName === 'ID_INVOICE')
            && !(column.columnName === 'Aging' && [1, 11].includes(ID_RELATORIO))
            && !fieldsToHide?.byEntity?.some(field => field === `${relation.tableName}.${column.columnName}`)
          ))
          .map((column, index) => (
            <CustomReportRow
              key={`list-item-report-${index}`}
              column={column}
              startOrder={startOrder}
              index={index}
              relation={relation}
              setOpen={setOpen}
              ID_REPORT={ID_RELATORIO}
            />
          ))}
      </LocalizationProvider>
    </>
  )
}