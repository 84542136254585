import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Autocomplete,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { Company } from "../../../../../types/company";
import { LocationBinProps } from "../../../../../types/locationBin";

let customRegimeScheme = yup.object({
  ID_CENTRO: yup.number().required(),
  DSC_LOCATION: yup.string().max(25).required(),
  ATIVO: yup.boolean().notRequired(),
});

interface ShipmentFormProps {
  shipment: LocationBinProps;
  center: any[];
  companies: Company[];
  onSubmit: (customRegime: LocationBinProps) => void;
  onCancel: () => void;
  isLoading?: boolean;
  errors?: string[];
  controlEdit: {
    loading: boolean;
    isInUse: boolean;
  };
  readOnly?: boolean;
}

export default function Shipment({
                                   shipment,
                                   center,
                                   onSubmit,
                                   onCancel,
                                   isLoading = false,
                                   errors = [],
                                   controlEdit,
                                   readOnly
                                 }: ShipmentFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(customRegimeScheme),
  });

  useEffect(() => {
    reset(shipment);
  }, [shipment, reset]);

  const handleFormSubmit = (data: LocationBinProps) => {
    onSubmit(data);
  };

  const handleCancel = () => {
    reset({ DSC_LOCATION: "" });
    onCancel();
  };

  return (
      <form style={{ width: "100%" }} onSubmit={handleSubmit(handleFormSubmit)}>
        {errors.map((error) => (
            <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
              {error}
            </Alert>
        ))}

        <Grid
            container
            spacing={2}
            marginTop={1}
            border={1}
            padding={2}
            borderColor={"#7b7b7b"}
            borderRadius={2}
            alignItems="center"
        >
          <Grid marginBottom={5} container justifyContent="flex-end" spacing={2}>
            <Grid item xs={4}>
              <Controller
                  control={control}
                  name="ID_CENTRO"
                  render={({ field: { onChange, value, ref, ...field } }) => (
                      <Autocomplete
                          options={center || []}
                          disabled={controlEdit.isInUse || readOnly}
                          getOptionLabel={(option) => option.description}
                          value={center.find((item) => item.id === value) || null}
                          onChange={(_, newValue) => {
                            onChange(newValue?.id ?? null);
                          }}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  {...field}
                                  label="Company - Plant"
                                  size="small"
                                  variant="outlined"
                                  inputRef={ref}
                                  error={!!formErrors.ID_CENTRO}
                              />
                          )}
                      />
                  )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                  control={control}
                  name="DSC_LOCATION"
                  render={({ field: { value } }) => (
                      <TextField
                          fullWidth
                          type="text"
                          label="Description"
                          size="small"
                          variant="outlined"
                          disabled={controlEdit.isInUse || readOnly}
                          defaultValue={value}
                          error={!!formErrors.DSC_LOCATION}
                          InputLabelProps={{ shrink: !!value }}
                          inputProps={{ maxLength: 25 }}
                          {...register("DSC_LOCATION")}
                      />
                  )}
              />
            </Grid>

            <Grid item xs={2}>
              <Controller
                  render={({ field: { value } }) => (
                      <FormControlLabel
                          control={
                            <Switch
                                checked={!!value}
                                disabled={readOnly}
                                inputProps={{ "aria-label": "controlled" }}
                                {...register("ATIVO")}
                            />
                          }
                          labelPlacement="start"
                          label="Status"
                      />
                  )}
                  name="ATIVO"
                  control={control}
              />
            </Grid>

            <Grid item xs={12} />
          </Grid>

          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid>
              <Button
                  variant="outlined"
                  onClick={handleCancel}
                  disabled={isLoading}
                  data-testid="cancel-button"
              >
                Cancel
              </Button>
            </Grid>
            {!readOnly && (
                <Grid marginLeft={2}>
                  <Button
                      variant="contained"
                      disabled={isLoading}
                      type="submit"
                      data-testid="submit-button"
                  >
                    Confirm
                  </Button>
                </Grid>
            )}
          </Grid>
        </Grid>
      </form>
  );
}
