import {
    Alert,
    Button,
    Grid,
    TextField,
    Autocomplete,
    Box,
    FormControlLabel,
    Radio,
    RadioGroup,
} from "@mui/material";
import { useEffect } from "react";
import { useForm, Controller, useFormState } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SlaService } from "../../../../../types/sla";
import { ComboBoxType } from "../../../../../types/general";

/**
 * Schema de validação com Yup
 */
let slaScheme = yup.object({
    ID_CENTRO: yup.number().required("Campo obrigatório."),
    ID_TIPO_INVOICE: yup.string().required("Campo obrigatório."),
    ID_STATUS_INVOICE: yup.string().required("Campo obrigatório."),
    ID_MODAL: yup.string().nullable(),
    TODAS_DIVERGENCIAS: yup.boolean().required(),
    ID_DIVERGENCIA: yup.number().when("TODAS_DIVERGENCIAS", {
        is: false,
        then(schema) {
            return schema.required("Campo obrigatório se não for 'Todas'.");
        },
        otherwise(schema) {
            return schema.nullable();
        },
    }),
    ID_PRIORIDADE: yup.number().nullable(),
    ID_FRETE_INCO: yup.string().nullable(),
    SLA_PRINCIPAL: yup
        .string()
        .required("Campo obrigatório.")
        .max(3, "Máximo de 3 caracteres."),
    SLA_SECUNDARIO: yup
        .string()
        .max(3, "Máximo de 3 caracteres.")
        .nullable()
        .notRequired(),
});

interface SlaFormProps {
    readOnly: boolean;
    entityInUse: boolean;

    sla: SlaService;
    slaId: number;

    center: ComboBoxType[];
    autoDiscrepancy: ComboBoxType[];
    invoiceType: ComboBoxType[];
    serviceLevels: ComboBoxType[];
    TransportationMode: ComboBoxType[];
    discrepancy: ComboBoxType[];
    TriageInvoiceStatus: ComboBoxType[];
    IncotermsFreightType: ComboBoxType[];

    isEditing: boolean;
    onSubmit: (sla: SlaService) => void;
    onCancel: () => void;
    externalErrors?: string[];

    isLoading?: boolean;
}

export default function SlaForm({
                                    readOnly,
                                    sla,
                                    slaId,
                                    center,
                                    autoDiscrepancy,
                                    invoiceType,
                                    serviceLevels,
                                    TransportationMode,
                                    discrepancy,
                                    TriageInvoiceStatus,
                                    IncotermsFreightType,
                                    isEditing,
                                    onSubmit,
                                    onCancel,
                                    externalErrors = [],
                                }: SlaFormProps) {
    // Aqui recuperamos também o formState (que contém os erros de validação do Yup).
    const {
        handleSubmit,
        reset,
        control,
        watch,
        formState: { errors: formErrors },
    } = useForm({
        resolver: yupResolver(slaScheme),
        defaultValues: {
            // Se quiser, pode definir defaults aqui
            // ou esperar que o `reset` faça isso via `formattedSla`
        },
    });

    const allValues = watch();

    useEffect(() => {
        console.log(`slaId: ${slaId}`, sla);
        if (sla) {
            const formattedSla = {
                ...sla,
                // Se precisar formatar algum valor antes do reset, faça aqui
            };
            reset(formattedSla);
        }
    }, [sla, slaId, reset]);

    const handleFormSubmit = (newSla: any) => {
        onSubmit({
            ...newSla,
            SLA_PRINCIPAL: parseInt(newSla.SLA_PRINCIPAL),
            SLA_SECUNDARIO: parseInt(newSla.SLA_SECUNDARIO),
        });
    };

    const handleCancel = () => {
        reset({});
        onCancel();
    };

    return (
        <form
            style={{ width: "100%" }}
            onSubmit={handleSubmit(handleFormSubmit)}
            data-testid="sla-form"
        >
            {/* Erros externos, vindos de fora do react-hook-form */}
            {externalErrors.map((error) => (
                <Alert key={error} severity="error" style={{ marginBottom: 20 }}>
                    {error}
                </Alert>
            ))}

            <Grid
                data-testid="search"
                container
                marginTop={1}
                border={1}
                padding={2}
                spacing={2}
                borderColor={"#7b7b7b"}
                borderRadius={2}
                alignItems="center"
            >
                <Grid container spacing={2} marginBottom={2}>
                    <Grid item xs={12} md={6} lg={3}>
                        <Controller
                            name="ID_CENTRO"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    options={center}
                                    getOptionLabel={(option) => option?.description || ""}
                                    value={center.find((item) => item.id === field.value) || null}
                                    disabled={isEditing}
                                    onChange={(_, newValue) => {
                                        field.onChange(newValue ? parseInt(newValue.id, 10) : "");
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Company - Plant"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            error={Boolean(formErrors.ID_CENTRO)}
                                            disabled={isEditing}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Controller
                            name="ID_TIPO_INVOICE"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    options={invoiceType}
                                    disabled={isEditing}
                                    getOptionLabel={(option) => option?.description || ""}
                                    value={
                                        invoiceType.find(
                                            (item) => item.description === field.value
                                        ) || null
                                    }
                                    onChange={(_, newValue) => {
                                        field.onChange(newValue ? newValue.description : "");
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Invoice Type"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            error={Boolean(formErrors.ID_TIPO_INVOICE)}
                                            disabled={isEditing}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Controller
                            name="ID_STATUS_INVOICE"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    options={TriageInvoiceStatus}
                                    disabled={isEditing}
                                    getOptionLabel={(option) => option?.description || ""}
                                    value={
                                        TriageInvoiceStatus.find((item) => item.id === field.value) ||
                                        null
                                    }
                                    onChange={(_, newValue) => {
                                        field.onChange(newValue ? newValue.description : "");
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Status Invoice"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            error={Boolean(formErrors.ID_STATUS_INVOICE)}
                                            disabled={isEditing}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Controller
                            name="ID_MODAL"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    options={TransportationMode}
                                    disabled={isEditing}
                                    getOptionLabel={(option) => option?.description || ""}
                                    value={
                                        TransportationMode.find((item) => item.id === field.value) ||
                                        null
                                    }
                                    onChange={(_, newValue) => {
                                        field.onChange(newValue ? newValue.description : "");
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Modal"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            error={Boolean(formErrors.ID_MODAL)}
                                            disabled={isEditing}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Controller
                            name="TODAS_DIVERGENCIAS"
                            control={control}
                            defaultValue={sla.TODAS_DIVERGENCIAS || true}
                            render={({ field }) => (
                                <Box display="flex" alignItems="center">
                                    <Box marginRight={4} marginLeft={1}>
                                        Discrepancy
                                    </Box>
                                    <RadioGroup
                                        {...field}
                                        row
                                        data-testid="radio-discrepancy"
                                        onChange={(e) => {
                                            field.onChange(JSON.parse(e.target.value));
                                        }}
                                    >
                                        {discrepancy.map((option, index) => (
                                            <Box
                                                key={option.id}
                                                marginRight={index !== discrepancy.length - 1 ? 4 : 0}
                                            >
                                                <FormControlLabel
                                                    value={option.id}
                                                    control={<Radio />}
                                                    label={option.description}
                                                    disabled={isEditing || readOnly}
                                                />
                                            </Box>
                                        ))}
                                    </RadioGroup>
                                </Box>
                            )}
                        />
                        {/* Se quiser exibir erro específico para TODAS_DIVERGENCIAS */}
                        {formErrors.TODAS_DIVERGENCIAS && (
                            <Box color="error.main" fontSize="0.75rem" marginLeft={1}>
                                {formErrors.TODAS_DIVERGENCIAS.message}
                            </Box>
                        )}
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Controller
                            name="ID_DIVERGENCIA"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    options={autoDiscrepancy}
                                    disabled={
                                        allValues?.TODAS_DIVERGENCIAS === undefined ||
                                        allValues?.TODAS_DIVERGENCIAS ||
                                        isEditing
                                    }
                                    getOptionLabel={(option) => option?.description || ""}
                                    value={
                                        autoDiscrepancy.find((item) => item.id === field.value) ||
                                        null
                                    }
                                    onChange={(_, newValue) => {
                                        field.onChange(newValue ? Number(newValue.id) : null);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Specific Auto Discrepancy"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            error={Boolean(formErrors.ID_DIVERGENCIA)}
                                            disabled={
                                                allValues?.TODAS_DIVERGENCIAS === undefined ||
                                                allValues?.TODAS_DIVERGENCIAS ||
                                                isEditing
                                            }
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Controller
                            name="ID_PRIORIDADE"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    options={serviceLevels}
                                    disabled={isEditing}
                                    getOptionLabel={(option) => option?.description || ""}
                                    value={
                                        serviceLevels.find((item) => item.id === field.value) ||
                                        null
                                    }
                                    onChange={(_, newValue) => {
                                        field.onChange(newValue ? parseInt(newValue.id, 10) : "");
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Service Level"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            error={Boolean(formErrors.ID_PRIORIDADE)}
                                            disabled={isEditing}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Controller
                            name="ID_FRETE_INCO"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    options={IncotermsFreightType}
                                    disabled={isEditing}
                                    getOptionLabel={(option) => option?.description || ""}
                                    value={
                                        IncotermsFreightType.find((item) => item.id === field.value) ||
                                        null
                                    }
                                    onChange={(_, newValue) => {
                                        field.onChange(newValue ? newValue.id : "");
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Incoterm Freight"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            error={Boolean(formErrors.ID_FRETE_INCO)}
                                            disabled={isEditing}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Controller
                            name="SLA_PRINCIPAL"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Principal SLA"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    inputProps={{ maxLength: 3 }}
                                    error={Boolean(formErrors.SLA_PRINCIPAL)}
                                    onChange={(e) => {
                                        const inputValue = e.target.value.replace(/\D/g, "");
                                        field.onChange(inputValue);
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Controller
                            name="SLA_SECUNDARIO"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Secondary SLA"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    inputProps={{ maxLength: 3 }}
                                    error={Boolean(formErrors.SLA_SECUNDARIO)}
                                    onChange={(e) => {
                                        const inputValue = e.target.value.replace(/\D/g, "");
                                        field.onChange(inputValue);
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>

                <Grid container justifyContent="flex-end" marginTop={2} spacing={2}>
                    <Grid>
                        <Button
                            variant="outlined"
                            onClick={handleCancel}
                            data-testid="cancel-button"
                        >
                            Cancel
                        </Button>
                    </Grid>
                    {!readOnly ? (
                        <Grid marginLeft={2}>
                            <Button
                                variant="contained"
                                type="submit"
                                data-testid="submit-button"
                            >
                                Confirm
                            </Button>
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>
        </form>
    );
}
