import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useList as useCenter } from "../../../services/centers";
import { getToken } from "../../../storage/auth";
import { useSnackbar } from "notistack";
import DataTableDefault from "../../../components/DataTable";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { Visibility } from "@mui/icons-material";
import { paginateConsult } from "../../../services/consult";
import SearchIcon from "@mui/icons-material/Search";
import { AutomaticAuthorizationProps } from "../../../types/automaticAuthorization";
import LoadingOverlay from "../../../components/LoadingOverlay";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

interface IParams {
  type: any;
  page: number;
  keyword: string;
  center: any[];
  sort: string;
  limit: number;
  orderField: string;
}

type IPayload = {
  center: AutomaticAuthorizationProps["ID_CENTRO"][];
  type: string;
  reference: string;
  sort: string;
  orderField: string;
  page: number;
  limit: number;
}

const types: any[] = [
  { value: "INVOICE", label: "Invoice", "type": "INVOICE" },
  { value: "WR", label: "WR", "type": "INVOICE" },
  { value: "WR_TRIAGE", label: "WR Triage", "type": "TRIAGE" },
  { value: "TRACKING", label: "Tracking", "type": "TRACKING" },
  { value: "PO", label: "PO", "type": "INVOICE" },
  { value: "SHIPMENT_AUTHORIZATION", label: "Shipment Authorization", "type": "SHIPMENT_AUTHORIZATION" },
  { value: "PROCESS", label: "Process", "type": "PROCESS" },
  { value: "HAWB", label: "HAWB/BL", "type": "PROCESS" },
  { value: "CUSTOM_CLEARENCE", label: "Custom Clearence", "type": "PROCESS" },
];

const urls: any = {
  "INVOICE": { 
    url: "/invoice/details",
    field: "invoice_ID_INVOICE",
  },
  "TRACKING": { 
    url: "/invoice/details",
    field: "invoice_ID_INVOICE",
  },
  "TRACKING_TRIAGE": { 
    url: "/goods-triage/details",
    field: "triage_ID_TRIAGEM",
    field2: "triages_ID_TRIAGEM"
  },
  "TRIAGE": { 
    url: "/goods-triage/details",
    field: "triage_ID_TRIAGEM",
  },
  "INVOICE_TRIAGE": { 
    url: "/goods-triage/details",
    field: "triage_ID_TRIAGEM",
  },
  "SHIPMENT_AUTHORIZATION": { 
    url: "/shipmentAuthorization",
    field: "boardingAuthorization_ID_AUTORIZACAO_EMBARQUE",
  },
  "PROCESS": { 
    url: "/boarding-process",
    field: "ID_PROCESSO",
  }
};

export default function Consult() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState<string>();
  const [params, setParams] = useState<IParams>({
    keyword: '', 
    center: [], 
    type: types[0],
    sort: 'updated_at', 
    orderField: 'DESC', 
    page: 1, 
    limit: 100 
  });
  const [data, setData] = useState<any>({ data: [], currentPage: 1, limit: 100, total: 0 });
  const [changedPageAt, setChangedPageAt] = useState<null | number>(null);

  const getTokenSession = async () => {
    const getTokens = (await getToken()) as any;
    setToken(getTokens);
  };

  const fetchData = async () => {
    setIsLoading(true);
    const { type, keyword, center, sort, orderField, page, limit } = params || {};
  
    const payload: IPayload = {
      center: center?.map(item => item.id),
      type: type?.value,
      reference: keyword?.trim(),
      sort, 
      orderField, 
      page, 
      limit
    }

    try {
      const response = await paginateConsult(payload);
      setData(response);

      const count = response?.total || 0;
      if (count === 0) {
        enqueueSnackbar("Not finding records that satisfy the filters", { variant: "error" });
        return;
      }

      if (count === 1 || (count > 1 && type?.value === 'WR_TRIAGE')) {
        const [record] = response.data || [];
        const { type, value: typeValue } = params?.type || {};
        let suffix = record?.triages_ID_TRIAGEM && !['INVOICE', 'WR', 'PO'].includes(typeValue) ? '_TRIAGE' : ''
        if (typeValue === 'TRACKING' && record?.triage_ID_TRIAGEM) {
          suffix = '_TRIAGE'
        }
        const label = `${type}${suffix}` as string;
        const element = urls[label];
        const idReference = record[element.field] || record[element.field2];
        const url = element?.url && `${element?.url}/${idReference}`;

        if (!idReference) {
          enqueueSnackbar("Could not find corresponding reference", { variant: "error" });
        }

        if (url) navigate(url);
        return;
      }

      setOpenDeleteModal(true);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error fetching data", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  }

  const openURL = (url: string) => {
    if (!url) return null;
    window.open(url);
  }

  useEffect(() => {
    getTokenSession();
    setChangedPageAt(null);
  }, []);

  useEffect(() => {
    if (changedPageAt) {
      fetchData();
    }
  }, [changedPageAt]);

  const [base] = useState<IParams>({
    page: 1,
    limit: 100,
  } as IParams);

  const { combo: dataCenterCombo } = useCenter(base, true);

  const handleRequest = (requestParams: any) => {
    setParams((prevState) => ({ ...prevState, ...requestParams }));
    setChangedPageAt(new Date().getTime());
  };

  const POPUP_WR: GridColDef[] = [
    {
      field: "invoice_ID_CENTRO",
      headerName: "PLANT/CENTER",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      width: 400,
      renderCell: ({ row }) => {
        const info = dataCenterCombo.find((item: any) => item.id.toString() === row?.invoice_ID_CENTRO?.toString());
        return info?.description;
      },
    },
    {
      field: "invoice_ID_INVOICE",
      headerName: "ID",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      flex: 1,
    },
    {
      field: "invoice_NUM_INVOICE",
      headerName: "INVOICE",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      flex: 1,
    },
    {
      field: "invoice_DATA_INVOICE",
      headerName: "INVOICE DATA",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => {
        return (row?.invoice_DATA_INVOICE && dayjs(row.invoice_DATA_INVOICE).format("MM/DD/YYYY")) || '';
      }
    },
    {
      field: "po_NUMERO_PO",
      headerName: "PO",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      flex: 1,
    },
    {
      field: "invoice_ID_STATUS_INVOICE",
      headerName: "STATUS",
      headerClassName: "super-app-theme--header white-space-no-wrap",
      cellClassName: 'dashboard-cell',
      width: 230,
    },    
    {
      field: "open",
      type: "actions",
      headerName: "",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      sortable: false,
      width: 120,
      renderCell: ({ row }: any) => (
        <Button
          key={`open-${row?.invoice_ID_INVOICE}`}
          onClick={() => openURL(`/invoice/details/${row?.invoice_ID_INVOICE}`)}
          style={{ border: '1px solid', borderRadius: '5px', paddingTop: 0, paddingBottom: 0, fontSize: '12px' }}
        >
          VIEW
        </Button>
      )
    },
  ];

  const POPUP_WR_TRIAGE: GridColDef[] = [
    {
      field: "triage_ID_CENTRO",
      headerName: "PLANT/CENTER",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      width: 400,
      renderCell: ({ row }) => {
        const info = dataCenterCombo.find((item: any) => item.id.toString() === row?.triage_ID_CENTRO?.toString());
        return info?.description;
      },
    },
    {
      field: "gi_ID_INVOICE",
      headerName: "ID",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      flex: 1,
    },
    {
      field: "gi_NUM_INVOICE",
      headerName: "INVOICE",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      flex: 1,
    },
    {
      field: "gi_DATA_INVOICE",
      headerName: "INVOICE DATA",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => {
        return (row?.gi_DATA_INVOICE && dayjs(row.gi_DATA_INVOICE).format("MM/DD/YYYY")) || ''
      }
    },
    {
      field: "gp_NUMERO_PO",
      headerName: "PO",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      flex: 1,
    },
    {
      field: "gi_ID_STATUS_INVOICE",
      headerName: "STATUS",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      flex: 1,
    },    
    {
      field: "open",
      type: "actions",
      headerName: "",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      sortable: false,
      width: 120,
      renderCell: ({ row }: any) => (
        <Button
          key={`open-${row?.triageInvoices_ID_INVOICE_GTS}`}
          onClick={() => openURL(`/invoice/details/${row?.triageInvoices_ID_INVOICE_GTS}`)}
          style={{ border: '1px solid', borderRadius: '5px', paddingTop: 0, paddingBottom: 0, fontSize: '12px' }}
        >
          VIEW
        </Button>
      )
    },
  ];

  const POPUP_TRACKING: GridColDef[] = [
    {
      field: "invoice_ID_CENTRO",
      headerName: "PLANT/CENTER",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      width: 400,
      renderCell: ({ row }) => {
        const id = row?.triage_ID_TRIAGEM 
          ? row?.triage_ID_CENTRO?.toString()
          : row?.invoice_ID_CENTRO?.toString()

        const info = dataCenterCombo.find((item: any) => {
          return item.id.toString() === id
        });
        return info?.description;
      },
    },
    {
      field: "invoice_ID_INVOICE",
      headerName: "ID",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => {
        return row?.triage_ID_TRIAGEM ? row?.triage_ID_TRIAGEM : row?.invoice_ID_INVOICE;
      },
    },
    {
      field: "invoice_NUM_INVOICE",
      headerName: "REFERENCE",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => {
        return row?.triage_ID_TRIAGEM ? row?.triage_DSC_WAREHOUSE : row?.invoice_NUM_INVOICE;
      },
    },
    {
      field: "invoice_DATA_INVOICE",
      headerName: "DATE",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => {
        return dayjs(row?.triage_ID_TRIAGEM ? row?.triage_DATA_RECEB : row?.invoice_DATA_INVOICE).format("MM/DD/YYYY");
      },
    },
    {
      field: "po_NUMERO_PO",
      headerName: "PO",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => {
        return row?.triage_ID_TRIAGEM ? row?.triage_NUM_PO : row?.po_NUMERO_PO;
      },
    },
    {
      field: "invoice_ID_STATUS_INVOICE",
      headerName: "STATUS",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => {
        return row?.triage_ID_TRIAGEM ? row?.triage_ID_STATUS_TRIAGEM : row?.invoice_ID_STATUS_INVOICE;
      },
    },
    {
      field: "parceiroDomesticCarrier_NOME_PARCEIRO",
      headerName: "DOMESTIC CARRIER",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => {
        return row?.triage_ID_TRIAGEM ? row?.triage_DSC_CARRIER : row?.parceiroDomesticCarrier_NOME_PARCEIRO;
      },
    },
    {
      field: "TYPE",
      headerName: "TYPE",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => {
        return row?.triage_ID_TRIAGEM ? 'TRIAGE' : 'INVOICE';
      },
    },
    {
      field: "open",
      type: "actions",
      headerName: "",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      sortable: false,
      width: 120,
      renderCell: ({ row }: any) => (
        <Button
          key={`open-${row?.invoice_ID_INVOICE}`}
          onClick={() => openURL(row?.triage_ID_TRIAGEM ? `/goods-triage/details/${row?.triage_ID_TRIAGEM}` : `/invoice/details/${row?.invoice_ID_INVOICE}`)}
          style={{ border: '1px solid', borderRadius: '5px', paddingTop: 0, paddingBottom: 0, fontSize: '12px' }}
        >
          VIEW
        </Button>
      )
    },
  ];

  const POPUP_PO: GridColDef[] = [
    {
      field: "invoice_ID_CENTRO",
      headerName: "PLANT/CENTER",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      width: 300,
      renderCell: ({ row }) => {
        const info = dataCenterCombo.find((item: any) => item.id.toString() === row?.invoice_ID_CENTRO?.toString());
        return info?.description;
      },
    },
    {
      field: "invoiceItemsPo_NUM_LIN_PO",
      headerName: "PO LINE",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      width: 120,
      renderCell: ({ row }) => {
        return row
          .invoiceItemsPo?.map((item: { invoiceItemsPo_NUM_LIN_PO: any; }) => item.invoiceItemsPo_NUM_LIN_PO).join(',') || '';
      },
    },
    {
      field: "warehouse_NUM_WAREHOUSE",
      headerName: "WR",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      flex: 1,
    },
    {
      field: "invoice_ID_INVOICE",
      headerName: "ID",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      flex: 1,
    },
    {
      field: "invoice_NUM_INVOICE",
      headerName: "INVOICE",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      flex: 1,
    },
    {
      field: "invoice_DATA_INVOICE",
      headerName: "INVOICE DATE",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => {
        return (row?.invoice_DATA_INVOICE && dayjs(row.invoice_DATA_INVOICE).format("MM/DD/YYYY")) || ''
      },
    },
    {
      field: "invoice_ID_STATUS_INVOICE",
      headerName: "STATUS",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      width: 230,
    },
    {
      field: "open",
      type: "actions",
      headerName: "",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      sortable: false,
      width: 120,        
      renderCell: ({ row }: any) => (
        <Button
          key={`open-${row?.invoice_ID_INVOICE}`}
          onClick={() => openURL(`/invoice/details/${row?.invoice_ID_INVOICE}`)}
          style={{ border: '1px solid', borderRadius: '5px', paddingTop: 0, paddingBottom: 0, fontSize: '12px' }}
        >
          VIEW
        </Button>
      )
    },
  ];

  const POPUP_PROCESS: GridColDef[] = [
    {
      field: "SIGLA_PLANTA_CONSOLID",
      headerName: "PLANT/CENTER",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      flex: 1,
    },
    {
      field: "CODIGO_PROCESSO",
      headerName: "PROCESS",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      flex: 1,
    },
    {
      field: "CODIGO_HOUSE_HAWB",
      headerName: "HAWB",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      flex: 1,
    },
    {
      field: "DATA_HAWB",
      headerName: "HAWB DATE",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => {
        return (row?.DATA_HAWB && dayjs(row.DATA_HAWB).format("MM/DD/YYYY")) || '';
      }
    },
    {
      field: "parceiroAgenteCarga_NOME_PARCEIRO",
      headerName: "FREIGHT FORWARDER",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      flex: 1,
    },
    {
      field: "DSC_DESEMBARACO_DI",
      headerName: "CUSTOM CLEARENCE",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      width: 230,
    },
    {
      field: "open",
      type: "actions",
      headerName: "",
      headerClassName: "super-app-theme--header",
      cellClassName: 'dashboard-cell',
      sortable: false,
      width: 120,
      getActions: ({ row }: any) => ([
        <GridActionsCellItem
          color="primary"
          size="medium"
          key={`open-${row?.ID_PROCESSO}`}
          icon={<Visibility />}
          label="View"
          onClick={() => openURL(`/boarding-process/${row?.ID_PROCESSO}`)}
        />
      ]),
    },
  ];

  const columns: any = {
    INVOICE: POPUP_WR,
    WR: POPUP_WR,
    WR_TRIAGE: POPUP_WR_TRIAGE,
    TRACKING: POPUP_TRACKING,
    SHIPMENT_AUTHORIZATION: null,
    PO: POPUP_PO,
    PROCESS: POPUP_PROCESS,
    HAWB: POPUP_PROCESS,
    CUSTOM_CLEARENCE: POPUP_PROCESS,
  };

  console.log({ page: params.page })

  return (
    <div id="centers">
      <LoadingOverlay isLoading={isLoading} />
      <Grid marginTop={4}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h5">Document Consultation</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems="center" marginTop={1}>
          <Grid item xs={12}>
            <Paper
              variant='outlined'
              sx={{ paddingTop: 2, paddingLeft: 5, paddingBottom: 2, paddingRight: 15 }}
            >
              <Grid container spacing={2} alignItems="center" marginTop={1} marginBottom={5}>
                <Grid item xs={4}>
                  <Autocomplete
                    multiple
                    options={dataCenterCombo || []}
                    getOptionLabel={(option: any) => option.description}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        label="Center/Plant"
                        size="small"
                        variant="outlined"
                        multiline={false}
                      />
                    )}
                    onChange={(_, data: any) => { 
                      setParams((oldState: any) => ({ ...oldState, center: data }));
                    }}
                    value={params?.center || null}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    value={params?.keyword}
                    data-testid="description-input"
                    fullWidth
                    required
                    label="Reference"
                    variant="outlined"
                    size="small"
                    onChange={(value) => setParams((oldState: any) => ({ ...oldState, keyword: value.target.value }))}
                    onKeyDown={(event) => event.key === 'Enter' && fetchData()}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Autocomplete
                    options={types}
                    getOptionLabel={(option) => option.label}
                    value={params?.type || null}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        label="Type"
                        size="small"
                        variant="outlined"
                      />
                    )}
                    disableClearable
                    onChange={(_, data: any) => { 
                      setParams((oldState: any) => ({ ...oldState, type: data }));
                    }}
                  />
                </Grid>
                
                <Grid item xs={1}>
                  <Button
                    variant="contained"
                    disabled={!(params?.keyword && params?.type)}
                    onClick={fetchData}
                    data-testid="submit-button"
                  >
                    <SearchIcon /> Search
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Dialog
          scroll='paper'
          fullWidth={true}
          maxWidth='lg'
          open={openDeleteModal}
          onClose={() => {
            setParams((oldState: any) => ({
              ...oldState,
              sort: 'updated_at', 
              orderField: 'DESC', 
              page: 1,
              limit: 100
            }));
            setOpenDeleteModal(false)
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Select Data
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <DataTableDefault
                rowId={data?.data?.map((row: any) => row.id)}
                data-testid="custom-regime-data-table"
                data={data?.data}
                rowCount={data?.total}
                columns={columns[params.type?.value || 'INVOICE']}
                onPaginationModelChange={(e: any) => {
                  handleRequest({ page: e.page + 1, limit: e.pageSize });
                }}
                pagination
                initialState={{
                  pagination: { paginationModel: { page: params.page - 1, pageSize: params.limit } },
                }}
                paginationMode="server"
                pageSizeOptions={[10, 25, 50, 100]}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteModal(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </div>
  );
}
