import { AccordionDetails, Box, List, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CustomReportFields from "./customReportFields";
import { AccordionSummary } from "../../../../components/Accordion/AccordionSummary";
import { Accordion } from "../../../../components/Accordion/Accordion";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { keyCustomReportFields } from "./customReportRow";
import { useTranslation } from "../hooks/translation.hook";
import { translate } from "../../../utils/translation";
import { useCustomReport } from "../hooks/customReportData.hook";
import { useFieldsToHide } from "../hooks/fieldsToHide.hook";

export default function CustomReportTable({
    relation,
    entities,
    path,
    startOrder,
    ID_RELATORIO,
  }) {

  const { isThisPathOpen, togglePath } = useFieldsToHide();
  const { translations } = useTranslation();
  const { getValues, relationsToShow } = useCustomReport();
  const [entity, setEntity] = useState(null);
  const [open, setOpen] = useState(false);

  const handleAccordion = () => {
    togglePath(path);
    setOpen(!open);
  };

  const isEnableToBeSelected = () => {
    const customReportFieldsSelected = getValues(keyCustomReportFields);
    let enable = false;
    if (relation.tableNameOrigin === '') {
      enable = true;
    }

    customReportFieldsSelected?.forEach((customFieldItem) => {
      if (customFieldItem.NOME_TABELA === relation.tableNameOrigin) {
        enable = true;
      }
    });
    return enable;
  };

  useEffect(() => {
    if (!entity && entities && entities.length > 0) {
      const entityFounded = entities.find((entity) => entity.tableName === relation.tableName);
      setEntity(entityFounded || null); // Certifique-se de que entityFounded não é undefined
    }
  }, [entities, entity, relation.tableName]);

  return (
      <Accordion
          style={{ marginTop: 4 }}
          expanded={isThisPathOpen(path)}
          onChange={handleAccordion}
      >
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            style={{ borderRadius: '4px' }}
            disabled={!isEnableToBeSelected()}
            hasData
        >
          <Typography>{translate('RELATION', relation.tableName, translations)}</Typography>
          {/* <Typography>{relation.tableName} - {relation.columnName}</Typography> */}
        </AccordionSummary>
        {isThisPathOpen(path) && <AccordionDetails sx={{
          border: '1px solid',
          borderRadius: '0 0 4px 4px',
          borderTop: 'none',
          borderColor: 'rgba(0, 0, 0, 0.4)',
          backgroundColor: '#F5F5F5',
          marginBottom: '18px',
        }}>
          <List dense={true}>
            {entity && (
                <CustomReportFields
                    entity={entity}
                    relation={relation}
                    startOrder={startOrder}
                    ID_RELATORIO={ID_RELATORIO}
                />
            )}
            {entity?.relations
                .filter((relationItem) => relationsToShow
                    .some((relationToShow) =>
                        relationToShow.NOME_TABELA === relationItem.tableName
                        && relationToShow.RELATION_NAME === relationItem.columnName
                        && relationToShow.TABELA_ORIGEM === entity.tableName
                    )
                )
                .map((relationItem, index) => (
                    <Box style={{ marginBottom: '4px' }} key={`table-${Math.random() * 20}`}>
                      <CustomReportTable
                          relation={{ ...relationItem, tableNameOrigin: relation.tableName, columnNameOrigin: relation.columnName }}
                          entities={entities}
                          path={`${path}.${relationItem.columnName}`}
                          startOrder={startOrder + entity.columns.length + index * 50}
                          ID_RELATORIO={ID_RELATORIO}
                      />
                    </Box>
                ))}
          </List>
        </AccordionDetails>}
      </Accordion>
  );
}
