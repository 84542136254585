import { Button } from '@mui/material';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import DataTableDefault from '../../../components/DataTable';
import { SearchParams } from '../../../types/triage';
import React, { useState, useEffect } from 'react';
import { Triage } from '../../../types/triage';
import { CarrierProps } from '../../../types/carrier';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { formatDate } from '../../utils/date';

// Interface para os parâmetros de busca
interface IParams {
  page: number;
  limit: number;
  keyword?: string;
  sort?: string;
  url?: string;
  param?: string;
}

interface DashboardTriagesTableProps {
  data: Triage[];
  count: number;
  carriers: CarrierProps[];
  params: IParams;
  searchParams: SearchParams;
  handleRequest: (params: Partial<IParams>) => void;
  onChangeSearchParam: (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  setSearchParams: React.Dispatch<React.SetStateAction<SearchParams>>;
}

const DashboardTriagesTable: React.FC<DashboardTriagesTableProps> = ({
  data,
  count,
  carriers,
  params,
  searchParams,
  handleRequest,
  setSearchParams,
}) => {
  const [urlSearchParams, setURLSearchParams] = useSearchParams();

  // Recupera o pageSize do localStorage ou usa 100 como padrão
  const savedPageSize = localStorage.getItem('triagePageSize');
  const initialPageSize = savedPageSize ? parseInt(savedPageSize, 10) : 100;

  // Recupera o page da URL, se disponível
  const urlPage = urlSearchParams.get('page');
  const initialPage = urlPage && !isNaN(+urlPage) ? +urlPage - 1 : 0;

  // Inicializa o paginationModel com valores da URL e localStorage
  const [paginationModel, setPaginationModel] = useState({
    page: initialPage, // Usa o valor da URL, default 0
    pageSize: initialPageSize,
  });

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [menuRowId, setMenuRowId] = useState<number | null>(null);
  const [menuPosition, setMenuPosition] = useState<{ top: number; left: number } | null>(null);

  const navigate = useNavigate();

  // Persiste o pageSize no localStorage e atualiza a URL
  useEffect(() => {
    localStorage.setItem('triagePageSize', paginationModel.pageSize.toString());
    const newUrlSearchParams = new URLSearchParams(urlSearchParams);
    newUrlSearchParams.set('pageSize', paginationModel.pageSize.toString());
    newUrlSearchParams.set('page', (paginationModel.page + 1).toString()); // Garante que page esteja na URL
    setURLSearchParams(newUrlSearchParams);
  }, [paginationModel.pageSize, paginationModel.page, urlSearchParams, setURLSearchParams]);

  // Sincroniza o params.limit com o pageSize do paginationModel
  useEffect(() => {
    if (params.limit !== paginationModel.pageSize || params.page !== paginationModel.page + 1) {
      handleRequest({ page: paginationModel.page + 1, limit: paginationModel.pageSize });
    }
  }, [paginationModel.pageSize, paginationModel.page, params.limit, params.page, handleRequest]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, rowId: number) => {
    const buttonRect = event.currentTarget.getBoundingClientRect();
    setAnchorEl(event.currentTarget);
    setMenuPosition({ top: buttonRect.bottom, left: buttonRect.left });
    setMenuRowId(rowId);
  };

  const handleClose = () => setAnchorEl(null);

  const handleTriageDetails = (event: React.MouseEvent<HTMLButtonElement>, triage: Triage) => {
    const path = `/goods-triage/details/${triage.ID_TRIAGEM}`;
    window.open(window.location.origin + path, '_blank');
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const columns: GridColDef[] = [
    {
      field: 'AGING',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
      sortable: true,
    },
    {
      field: 'WR',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => row?.warehouse?.NUM_WAREHOUSE || '',
    },
    {
      field: 'CARRIER',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
      type: 'singleSelect',
      renderCell: ({ row }) => row?.DSC_CARRIER || '',
      valueOptions: carriers.map((carrier) => carrier.NOME_TRANSPORTADOR),
    },
    {
      field: 'REFERENCE',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) =>
        (row?.triageVolumes.length > 0 && row?.triageVolumes[0].DSC_REFERENCIA_VOL) || '',
    },
    {
      field: 'PIECE NUMBER',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => row?.QTDE_VOL_TOTAL || 0,
    },
    {
      field: 'RECEIVED DATE',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => formatDate(row?.DATA_RECEB) || '',
    },
    {
      field: 'BIN LOCATION',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) =>
        (row?.triageVolumes.length > 0 && row?.triageVolumes[0].DSC_LOCATION) || '',
    },
    {
      field: 'STATUS',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      flex: 1,
      renderCell: ({ row }) => row.ID_STATUS_TRIAGEM || '',
    },
    {
      field: 'DSC_Actions',
      type: 'actions',
      headerName: 'Actions',
      headerClassName: 'dashboard-header',
      cellClassName: 'dashboard-cell',
      sortable: false,
      renderCell: ({ row }) => (
        <Button
          aria-describedby={id}
          onClick={(event) => handleTriageDetails(event, row as Triage)}
          style={{
            border: '1px solid',
            borderRadius: '5px',
            paddingTop: 0,
            paddingBottom: 0,
            fontSize: '12px',
          }}
        >
          VIEW
        </Button>
      ),
    },
  ];

  const initialSortModel: GridSortModel = [
    {
      field: 'AGING',
      sort: 'desc',
    },
  ];

  return (
    <div style={{ overflowX: 'auto' }}>
      <DataTableDefault
        rowId={data?.map((row) => row.ID_TRIAGEM)}
        data-testid="unit-data-table"
        data={data}
        columns={columns}
        rowCount={count}
        onPaginationModelChange={(model) => {
          setPaginationModel(model);
          handleRequest({ page: model.page + 1, limit: model.pageSize });
          const newUrlSearchParams = new URLSearchParams(urlSearchParams);
          newUrlSearchParams.set('page', (model.page + 1).toString());
          newUrlSearchParams.set('pageSize', model.pageSize.toString());
          setURLSearchParams(newUrlSearchParams);
        }}
        pagination
        paginationModel={paginationModel}
        initialState={{
          pagination: { paginationModel: { page: paginationModel.page, pageSize: paginationModel.pageSize } },
          sorting: {
            sortModel: searchParams.ORDER_FIELD
              ? [{ field: searchParams.ORDER_FIELD, sort: searchParams.ORDER_SORT }]
              : initialSortModel,
          },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        paginationMode="server"
        pinnedColumns={{ right: ['DSC_Actions'] }}
        onSortModelChange={(sortModel: GridSortModel) => {
          const [sortItem] = sortModel.length > 0 ? sortModel : initialSortModel;
          setSearchParams((prevState) => ({
            ...prevState,
            ORDER_FIELD: sortItem.field,
            ORDER_SORT: sortItem.sort || 'desc',
          }));
        }}
      />
    </div>
  );
};

export default DashboardTriagesTable;